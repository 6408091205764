<template>
  <div>
    <c-page-heading
      :heading="$t('auth.layout.welcomeTo')"
      :subHeading="$t('auth.layout.gatherings')"
    ></c-page-heading>

    <v-container class="mt-10">
      <v-row class="justify-center">
        <v-col cols="12" :sm="8" class="my-3 text-center">
          <div class="login-card">
            <div class="legacy-input-title">
              <h3 class="font-weight-light" data-cy="enterTheSixDigitCodeSentTo">
                {{ $t('auth.passcode.enterTheSixDigitCodeSentTo') }}
                <span class="font-weight-bold">{{ this.$store.getters['auth/user'].email }}</span>
              </h3>
            </div>
            <div>
              <input
                @keyup.enter="onSubmit"
                v-model="passcode"
                type="text"
                class="legacy-input"
                :placeholder="$t('auth.passcode.enterYourPasscode')"
                maxlength="6"
                data-cy="passcode"
              />
              <p class="error--text mb-2" v-if="error.length > 0">
                <small>{{ error }} </small>
              </p>
            </div>
            <v-btn
              @click.prevent="onSubmit"
              :disabled="isSubmitting"
              x-large
              color="primary"
              rounded
              type="submit"
              class="submit-btn-text"
              data-cy="submit-passcode"
            >
              {{ $t('common.submit') }}
            </v-btn>

            <div
              class="mt-4 primary--text pointer"
              @click="sendMeANewCode"
              data-cy="sendMeANewCode"
            >
              <h3 data-cy="didnTReceiveACode">
                {{ $t('auth.passcode.didnTReceiveACode') }}
              </h3>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isSubmitting: false,
      passcode: '',
      error: '',
    }
  },
  methods: {
    async onSubmit() {
      if (this.isSubmitting || this.validate() !== true) {
        return
      }

      this.isSubmitting = true
      await this.$store.dispatch('ui/toggleLoading', { isLoading: true })

      try {
        await this.$store.dispatch('auth/verifyPasscode', { passcode: this.passcode })
        await this.$store.dispatch('ecosystems/fetchMine')
        await this.$store.dispatch('timeZones/fetch')
        await this.$store.dispatch('avatars/fetch')
        await this.$store.dispatch('ui/toggleLoading', { isLoading: false })
        if (
          this.$route.query &&
          this.$route.query.ReturnUrl &&
          this.$route.query.ReturnUrl.toLowerCase() !== 'logout'
        ) {
          this.$router.push(this.$route.query.ReturnUrl)
        } else {
          this.$router.push({ path: '/' })
        }
        await this.$store.dispatch('auth/registerIPAddress')
      } catch (error) {
        this.isSubmitting = false
        this.$store.dispatch('ui/toggleLoading', { isLoading: false })
        this.showPasscodeApiError = true
        this.message = (error.response && error.response.data) || error.message || error.toString()
      }
    },

    validate() {
      this.error = ''
      if (this.passcode.length < 6) {
        this.error = this.$t('auth.passcode.pleaseProvideAValid_6DigitCode')
        return false
      }
      return true
    },
    sendMeANewCode() {
      if (this.$route.query.ReturnUrl) {
        this.$router.push({
          path: '/account/login',
          query: { ReturnUrl: this.$route.query.ReturnUrl },
        })
      } else {
        this.$router.push('/account/login')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cardHeader {
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
}
</style>
